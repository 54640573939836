import {format, distanceInWords, differenceInDays} from 'date-fns'
import ja from 'date-fns/locale/ja'

import React from 'react'
import styled from 'styled-components'

import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'

import HeadingSpan from './heading-span'
import Button from './button'

import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.article`
  display: block;
  padding: 4rem 0;
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 680px;
  }
`

const Heading = styled.div`
  max-width: 740px;
  margin: 0 auto;
`

const Title = styled.h1`
  font-size: 2.0rem;
  font-weight: 700;
  font-family: ${fontfamily.jaRounded};
  display: block;
  margin: 0.8rem 2.4rem 3.6rem 2.4rem;
  border-bottom: 1px solid ${colors.gray};
  padding-bottom: 2.4rem;
`

const Aside = styled.aside`
  display: block;
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
`

const DateCats = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 0 2.4rem;
`

const DateTime = styled.span`
  display: block;
  color: ${colors.lightNavy};
  margin: 0 0.8rem 0 0;
`

function Admissions (props) {
  const {_rawBody, grade, location, title, mainImage, publishedAt} = props
  return (
    <Wrapper>
      <HeadingSpan title='語学講座・大人向け講座など' titleEn='WORKSHOPS' />
      <Heading>
        <Aside>
          <DateCats>
            {publishedAt && (
              <DateTime>
                公開日:
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'YYYY年MM月DD日(dd)', {locale: ja})}
              </DateTime>
            )}
          </DateCats>
        </Aside>
        <Title>
          {title}
        </Title>
      </Heading>

      {mainImage && mainImage.asset &&
        <ImageWrapper>
          <Image
            src={imageUrlFor(buildImageObj(mainImage))
              .width(2400)
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </ImageWrapper>
      }
      {_rawBody && (
        <Text>
          <PortableText blocks={_rawBody} />
        </Text>
      ) }
    </Wrapper>
  )
}

export default Admissions
